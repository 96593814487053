import React from 'react';

import { ROUTES } from '../consts/routes';
import { HowItWorks } from '../components/HowItWorks/HowItWorks';
import { BecomeBeneficiary } from '../components/BecomeBeneficiary/BecomeBeneficiary';
import { BecomePartner } from '../components/BecomePartner/BecomePartner';
import { Contact } from '../components/Contact/Contact';

const getComponent = name => () => (
  <div>
    {`page: ${name}`}
  </div>
);

export const WEB_ROUTES = [
  {
    exact: true,
    path: ROUTES.HOW_IT_WORKS,
    component: HowItWorks,
    label: 'Jak to działa?',
  },
  {
    exact: true,
    path: ROUTES.BECOME_A_BENEFICIARY,
    component: BecomeBeneficiary,
    label: 'Zostań beneficjentem',
  },
  {
    exact: true,
    path: ROUTES.BECOME_A_PARTNER,
    component: BecomePartner,
    label: 'Zostań partnerem',
  },
  {
    exact: true,
    path: ROUTES.CONTACT,
    component: Contact,
    label: 'Kontakt',
  },
  {
    path: '*',
    component: getComponent('404'),
  },
];
