/* eslint-disable */
export const getImages = () => [
  require('./become_a_beneficiary.gif'),
  require('./become_a_partner.gif'),
  require('./icon_back.svg'),
  require('./icon_close.svg'),
  require('./icon_menu.svg'),
  require('./icon_play.svg'),
  require('./icon_scroll.svg'),
  require('./logo.svg'),
  require('./slide_first.gif'),
  require('./slide_second.gif'),
  require('./slide_third.gif'),
  require('./slide_fourth.gif'),
  require('./slide_fifth.gif'),
  require('./slide_sixth.gif'),
  require('./slide_seventh.gif'),
  require('./slide_eight.gif'),
  require('./slide_ninth.gif'),
];
