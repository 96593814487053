import React from 'react';

import anim from '../../../../assets/slide_sixth.gif';

import { SlideBase } from '../SlideBase/SlideBase';

export const SlideSixth = props => (
  <SlideBase
    {...props}
    animation={anim}
    text="Wyobraź sobie ile transakcji jest przeprowadzanych w Twoim mieście."
  />
);
