import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

export const TextArea = ({
  name,
  onChange,
  placeholder,
  value,
}) => (
  <div className={styles.TextArea}>
    <textarea
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      rows={4}
      type="text"
      value={value}
    />
  </div>
);

TextArea.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

TextArea.defaultProps = {
  name: '',
  onChange() {},
  placeholder: '',
  value: '',
};
