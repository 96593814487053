import { SlideFirst } from './SildeFirst/SlideFirst';
import { SlideSecond } from './SlideSecond/SlideSecond';
import { SlideThird } from './SlideThird/SlideThird';
import { SlideFourth } from './SlideFourth/SlideFourth';
import { SlideFifth } from './SlideFifth/SlideFifth';
import { SlideSixth } from './SlideSixth/SlideSixth';
import { SlideSeventh } from './SlideSeventh/SlideSeventh';
import { SlideEight } from './SlideEight/SlideEight';
import { SlideNinth } from './SlideNinth/SlideNinth';
import { SlideTenth } from './SlideTenth/SlideTenth';

import styles from './styles.scss';

export const SLIDES = [
  {
    component: SlideFirst,
    className: styles.SlideFirst,
  },
  {
    component: SlideSecond,
    className: styles.SlideSecond,
  },
  {
    component: SlideThird,
    className: styles.SlideThird,
  },
  {
    component: SlideFourth,
    className: styles.SlideFourth,
  },
  {
    component: SlideFifth,
    className: styles.SlideFifth,
  },
  {
    component: SlideSixth,
    className: styles.SlideSixth,
  },
  {
    component: SlideSeventh,
    className: styles.SlideSeventh,
  },
  {
    component: SlideEight,
    className: styles.SlideEight,
  },
  {
    component: SlideNinth,
    className: styles.SlideNinth,
  },
  {
    component: SlideTenth,
    className: styles.SlideTenth,
  },
];
