import React from 'react';

import animation from '../../assets/become_a_beneficiary.gif';
import { BeneficiaryForm } from '../common/BeneficiaryForm/BeneficiaryForm';
import { Logo } from '../common/Logo/Logo';
import { Footer } from '../common/Footer/Footer';

import styles from './styles.scss';

// eslint-disable-next-line
const text = 'Naszym celem jest wspieranie ogólnopolskich i lokalnych organizacji społecznych, które podejmują działania charytatywne. Zawiązaliśmy koalicję dużych organizacji pozarządowych, które wspólnie z nami przygotowują pierwsze zbiórki w ramach projektu ZAOKRĄGLAMY.';
// eslint-disable-next-line
const text2 = 'Po wdrożeniu mechanizmów zaokrąglania w sklepach na terenie całej Polski przystąpimy do organizacji zbiórek dla beneficjentów w całym kraju.';

export const BecomeBeneficiary = () => (
  <div className={styles.BecomeBeneficiary}>
    <div className={styles.Columns}>
      <div className={styles.Column}>
        <div className={styles.Content}>
          <div className={styles.Top}>
            <img
              className={styles.Animation}
              src={animation}
              alt=""
            />
            <h2 className={styles.Title}>
              Zostań beneficjentem
            </h2>
          </div>
          <div className={styles.Text}>
            {text}
            <br />
            {text2}
          </div>
        </div>
      </div>
      <div className={styles.Column}>
        <BeneficiaryForm className={styles.Form} />
        <Logo className={styles.Logo} />
      </div>
    </div>
    <Footer />
  </div>
);
