import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import axios from 'axios';

import { Input } from '../Input/Input';
import { RoundedButton } from '../RoundedButton/RoundedButton';
import { isEmail } from '../../../utils/validators';

import styles from './styles.scss';

export class BeneficiaryForm extends Component {
  getInitialState = () => ({
    email: '',
    isLoading: false,
    name: '',
    phone: '',
  });

  state = this.getInitialState();

  isValid = () => {
    const {
      email,
      name,
      phone,
    } = this.state;

    if (email === ''
      || !isEmail(email)
      || phone === ''
      || name === ''
    ) {
      return false;
    }

    return true;
  }

  handleChangeInput = type => ({ target: { value } }) => this.setState({ [type]: value });

  handleSendButton = () => {
    const {
      email,
      name,
      phone,
    } = this.state;

    this.setState({ isLoading: true });

    const params = new URLSearchParams();
    params.append('name', name);
    params.append('email', email);
    params.append('phone', phone);

    axios.post('/message_beneficiary.php', params)
      .then(() => {
        this.setState(this.getInitialState());
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const {
      className,
    } = this.props;
    const {
      email,
      isLoading,
      name,
      phone,
    } = this.state;
    const isDisabled = !this.isValid();

    return (
      <div
        className={classnames(
          styles.BeneficiaryForm,
          className,
        )}
      >
        <h4 className={styles.Title}>
          Chcesz
        </h4>
        <h4 className={styles.Title}>
          do nas dołączyć
        </h4>
        <div className={styles.Subtitle}>
          <h5 className={styles.Bold}>
            Zostaw nam swoje dane,
          </h5>
          <span>
            &nbsp;
          </span>
          <h5>
            odezwiemy się w ciągu 24h
          </h5>
        </div>
        <Input
          onChange={this.handleChangeInput('name')}
          placeholder="imię i nazwisko"
          value={name}
        />
        <Input
          onChange={this.handleChangeInput('email')}
          placeholder="email"
          type="email"
          value={email}
        />
        <Input
          onChange={this.handleChangeInput('phone')}
          placeholder="telefon"
          value={phone}
        />
        <RoundedButton
          className={styles.SendButton}
          label="Wyślij"
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={this.handleSendButton}
        />
      </div>
    );
  }
}

BeneficiaryForm.propTypes = {
  className: PropTypes.string,
};

BeneficiaryForm.defaultProps = {
  className: '',
};
