import React from 'react';
import { HashRouter } from 'react-router-dom';
import Preload from 'react-preload';

import { getImages } from '../../../assets';
import { WEB_ROUTES } from '../../../router/config';
import { Routing } from '../Routing/Routing';
import { ScrollToTop } from '../ScrollToTop/ScrollToTop';
import { Spinner } from '../../Spinner/Spinner';

import '../../../styles/main.scss';

const images = getImages();

const App = () => (
  <Preload
    images={images}
    loadingIndicator={<Spinner />}
    mountChildren
    resolveOnError
  >
    <HashRouter>
      <ScrollToTop>
        <Routing routes={WEB_ROUTES} />
      </ScrollToTop>
    </HashRouter>
  </Preload>
);

export default App;
