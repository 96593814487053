import React from 'react';
import PropTypes from 'prop-types';

import SkyboatLogo from '../../../assets/logo_skyboat.png';
import TaktototakLogo from '../../../assets/logo_taktototak.png';
import Projekt86Logo from '../../../assets/logo_projekt86.png';
import StereotypStudioLogo from '../../../assets/logo_stereotyp_studio.png';
import RedOctopusLogo from '../../../assets/logo_red_octopus.png';

import styles from './styles.scss';

const LOGOS = [
  { src: SkyboatLogo, url: 'http://skyboat.pl' },
  { src: TaktototakLogo, url: 'http://taktototak.pl' },
  { src: Projekt86Logo, url: 'http://projekt86.io' },
  { src: StereotypStudioLogo, url: 'http://www.facebook.com/StereotypStudio' },
  { src: RedOctopusLogo, url: 'http://www.redoctopusweb.com/' },
];

const LogoItem = ({ src, url }) => (
  <a
    href={url}
    key={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    <img
      alt=""
      className={styles.LogoItem}
      src={src}
    />
  </a>
);

LogoItem.propTypes = {
  url: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

export const Footer = () => (
  <footer className={styles.Footer}>
    <div className={styles.Content}>
      <span>
        DESIGNED & CREATED BY:
      </span>
      <div className={styles.Logos}>
        {LOGOS.map(LogoItem)}
      </div>
    </div>
  </footer>
);
