import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import IconMenu from '../../../../assets/icon_menu.svg';
import IconBack from '../../../../assets/icon_back.svg';

import styles from './styles.scss';

export class Navigation extends Component {
  constructor(props) {
    super(props);

    this.linksRef = {};
    props.routes.forEach(({ path }) => {
      this.linksRef[path] = React.createRef();
    });

    this.state = {
      activeLink: {
        left: 0,
        width: 0,
      },
      overLink: null,
    };
  }

  componentDidMount() {
    const { currentPath } = this.props;

    this.updateUnderline(currentPath);
  }

  componentWillReceiveProps(nextProps, state) {
    const { currentPath } = this.props;
    const { currentPath: nextCurrentPath } = nextProps;

    if (currentPath !== nextCurrentPath) {
      this.updateUnderline(nextCurrentPath);
    }
  }

  getUnderlineProps = (path) => {
    const currentLinkRef = this.linksRef[path] && this.linksRef[path].current;
    if (!currentLinkRef) {
      return {};
    }

    // eslint-disable-next-line
    const currentLinkBounds = ReactDOM
      .findDOMNode(currentLinkRef)
      .getBoundingClientRect();

    // eslint-disable-next-line
    const parentLinkBounds = ReactDOM
      .findDOMNode(currentLinkRef)
      .parentElement
      .getBoundingClientRect();

    const left = currentLinkBounds.left - parentLinkBounds.left;
    const { width } = currentLinkBounds;

    return { left: left + 28, width: width - 56 };
  }

  updateUnderline = (path) => {
    const { left, width } = this.getUnderlineProps(path);

    this.setState({
      activeLink: { left, width },
    });
  }

  handleLinkOver = path => () => {
    const { left, width } = this.getUnderlineProps(path);

    this.setState({ overLink: { left, width } });
  }

  handleLinkOut = () => this.setState({ overLink: null });

  renderLink = ({ path, label }, index) => {
    const { currentPath } = this.props;
    const selected = path === currentPath;

    return (
      <Link
        className={classnames(
          styles.Link,
          {
            [styles.Link__selected]: selected,
          },
        )}
        ref={this.linksRef[path]}
        key={path}
        replace={selected}
        to={path}
        onBlur={this.handleLinkOut}
        onFocus={this.handleLinkOver(path)}
        onMouseOver={this.handleLinkOver(path)}
        onMouseOut={this.handleLinkOut}
      >
        {label}
      </Link>
    );
  }

  renderUnderline = () => {
    const {
      activeLink: { left, width },
      overLink,
    } = this.state;

    if (overLink) {
      return (
        <div
          className={styles.Underline}
          style={{ left: overLink.left, width: overLink.width }}
        />
      );
    }

    return (
      <div
        className={styles.Underline}
        style={{ left, width }}
      />
    );
  }

  render() {
    const {
      className,
      isOpen,
      onBack,
      onToggle,
      routes,
    } = this.props;

    return (
      <nav className={className}>
        <div
          className={styles.IconMenuWrapper}
          onClick={onToggle}
          onKeyDown={onToggle}
          role="button"
          tabIndex={0}
        >
          <img
            alt=""
            className={styles.IconMenu}
            src={IconMenu}
          />
        </div>
        <div
          className={classnames(
            styles.Links,
            {
              [styles.Links__opened]: isOpen,
            },
          )}
        >
          <div
            className={styles.IconBackWrapper}
            onClick={onBack}
            onKeyDown={onBack}
            role="button"
            tabIndex={0}
          >
            <img
              alt=""
              className={styles.IconBack}
              src={IconBack}
            />
          </div>
          {routes.map(this.renderLink)}
          {this.renderUnderline()}
        </div>
      </nav>
    );
  }
}

Navigation.propTypes = {
  className: PropTypes.string,
  currentPath: PropTypes.string,
  isOpen: PropTypes.bool,
  onBack: PropTypes.func,
  onToggle: PropTypes.func,
  routes: PropTypes.array.isRequired, // eslint-disable-line
};

Navigation.defaultProps = {
  className: '',
  currentPath: '',
  onBack() {},
  onToggle() {},
  isOpen: false,
};
