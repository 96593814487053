import React, { Component } from 'react';

import { BREAK_MID } from '../../consts/styles';
import { SlideContainer } from './SlideContainer/SlideContainer';
import { SLIDES } from './Slides';
import { Footer } from '../common/Footer/Footer';

import styles from './styles.scss';

export class HowItWorks extends Component {
  containerRef = React.createRef();

  state = {
    height: 0,
    top: 0,
    width: 0,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.upadeScroll);
    window.addEventListener('resize', this.updateSize);

    this.updateSize();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.upadeScroll);
    window.removeEventListener('resize', this.updateSize);
  }

  updateSize = () => {
    const {
      clientHeight,
      clientWidth,
    } = document.body;

    this.setState({
      height: clientHeight * SLIDES.length,
      width: clientWidth,
    });

    this.upadeScroll();
  }

  upadeScroll = () => {
    const {
      top,
    } = this.containerRef.current.getBoundingClientRect();

    this.setState({
      top: -top,
    });
  }

  renderSlide = ({ component, className }, index) => {
    const { height, top, width } = this.state;
    const isMobileView = width <= BREAK_MID;
    const calculateSlidingProps = index > 0;
    const pageHeight = height / SLIDES.length;
    const pageOffset = pageHeight * (index - 1);
    const marginTop = pageHeight - (top - pageOffset);
    const slideTop = Math.min(
      Math.max(marginTop, 0),
      pageHeight,
    );
    const slideHeight = pageHeight - slideTop;
    const slideProgress = !isMobileView
      ? 1 - (slideTop / pageHeight)
      : 1;
    const slideVisible = (marginTop < pageHeight && marginTop >= -pageHeight)
      || index === 0;
    const hideProgress = Math.max(
      Math.min((marginTop / pageHeight) + 1, 1),
      0,
    );
    const style = !isMobileView
      ? {
        height: calculateSlidingProps ? slideHeight : pageHeight,
        top: calculateSlidingProps ? slideTop : 0,
      }
      : null;

    if (!slideVisible && !isMobileView) {
      return null;
    }

    return (
      <SlideContainer
        className={className}
        component={component}
        height={pageHeight}
        hideProgress={hideProgress}
        isMobile={isMobileView}
        key={`slide_${index + 1}`}
        scrollProgress={slideProgress}
        style={style}
        visible={slideVisible}
      />
    );
  }

  render() {
    const { height } = this.state;

    return (
      <div
        className={styles.HowItWorks}
        style={{ height }}
        ref={this.containerRef}
      >
        {SLIDES.map(this.renderSlide)}
        <div className={styles.FooterWrapper}>
          <Footer />
        </div>
      </div>
    );
  }
}
