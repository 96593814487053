import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.scss';

export const SlideContainer = ({
  className,
  component: Component,
  height,
  hideProgress,
  isMobile,
  style,
  scrollProgress,
  visible,
}) => (
  <div
    className={classnames(
      styles.PageContainer,
      className,
    )}
    style={{ ...style }}
  >
    <Component
      height={height}
      hideProgress={hideProgress}
      isMobile={isMobile}
      scrollProgress={scrollProgress}
      visible={visible}
    />
  </div>
);

SlideContainer.propTypes = {
  className: PropTypes.string,
  component: PropTypes.func.isRequired,
  height: PropTypes.number,
  hideProgress: PropTypes.number,
  isMobile: PropTypes.bool,
  scrollProgress: PropTypes.number,
  style: PropTypes.object, // eslint-disable-line
  visible: PropTypes.bool,
};

SlideContainer.defaultProps = {
  className: '',
  hideProgress: 1,
  height: 0,
  isMobile: false,
  scrollProgress: 0,
  style: null,
  visible: true,
};
