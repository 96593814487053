import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Youtube from 'react-youtube';

import { VIDEO_ID } from '../../../../consts/youtube';
import { QuestionForm } from '../../../common/QuestionForm/QuestionForm';
import { MovieModal } from '../../../common/MovieModal/MovieModal';
import iconScroll from '../../../../assets/icon_scroll.svg';
import iconPlay from '../../../../assets/icon_play.svg';

import styles from './styles.scss';

const opts = {
  playerVars: {
    autoplay: 1,
    controls: 0,
    loop: 1,
    mute: 1,
    playlist: VIDEO_ID,
    rel: 0,
  },
};

export class SlideFirst extends PureComponent {
  state = {
    openVideo: false,
  }

  componentWillReceiveProps(nextProps) {
    const { visible, isMobile } = this.props;
    const { visible: nextVisible } = nextProps;

    if (this.player && !isMobile) {
      if (visible && !nextVisible) {
        this.player.pauseVideo();
      } else if (!visible && nextVisible) {
        this.player.playVideo();
      }
    }
  }

  handleOpenVideo = () => {
    const { isMobile } = this.props;

    if (this.player && !isMobile) {
      this.player.pauseVideo();
    }
    this.setState({ openVideo: true });
  }

  handleCloseVideo = () => {
    const { isMobile } = this.props;

    if (this.player && !isMobile) {
      this.player.playVideo();
    }
    this.setState({ openVideo: false });
  }

  handleOnReady = ({ target }) => {
    this.player = target;
  }

  render() {
    const { isMobile } = this.props;
    const { openVideo } = this.state;

    return (
      <div className={styles.WelcomeWrapper} >
        {isMobile
          ? <div className={styles.PlayerWrapper} />
          : (
            <Youtube
              className={styles.Player}
              containerClassName={styles.PlayerWrapper}
              onReady={this.handleOnReady}
              opts={opts}
              videoId={VIDEO_ID}
            />
          )
        }
        <div className={styles.Header}>
          <h2>
            {'ZAOKRĄGLAMY\nPOMAGAMY'}
          </h2>
          <span className={styles.Description}>
            {`Teraz możesz zmieniać Świat przy okazji zakupów.\n
            Wystarczy, że płacąc, zaokrąglamy płatność do pełnej złotówki\n
            - w ten sposób pomagamy potrzebującym.`}
          </span>
          <div
            className={styles.PlayButton}
            onClick={this.handleOpenVideo}
            onKeyDown={this.handleOpenVideo}
            role="button"
            tabIndex={0}
          >
            <img className={styles.IconPlay} src={iconPlay} alt="" />
            <span>
              ZOBACZ FILM
            </span>
          </div>
        </div>
        <QuestionForm
          className={styles.QuestionForm}
        />
        <div className={styles.ScrollWrapper}>
          <span>
            Jak to działa?
          </span>
          <img className={styles.IconScroll} src={iconScroll} alt="" />
        </div>
        <MovieModal
          isOpen={openVideo}
          onClose={this.handleCloseVideo}
        />
      </div>
    );
  }
}

SlideFirst.propTypes = {
  isMobile: PropTypes.bool,
  visible: PropTypes.bool,
};

SlideFirst.defaultProps = {
  isMobile: false,
  visible: true,
};
