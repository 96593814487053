import React from 'react';

import anim from '../../../../assets/slide_fourth.gif';

import { SlideBase } from '../SlideBase/SlideBase';

export const SlideFourth = props => (
  <SlideBase
    {...props}
    animation={anim}
    text="…zaokrąglasz go do pełnej złotówki"
  />
);
