import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

export class SlideBase extends Component {
  shouldComponentUpdate(nextProps) {
    const {
      height,
      scrollProgress,
    } = this.props;
    const {
      height: nextHeight,
      scrollProgress: nextScrollProgress,
    } = nextProps;

    return scrollProgress !== nextScrollProgress
      || height !== nextHeight;
  }

  render() {
    const {
      animation,
      height,
      scrollProgress,
      text,
    } = this.props;
    const animMargin = -1.5 * height * (1 - scrollProgress) || 0;
    const textMargin = animMargin * 0.1;

    return (
      <Fragment>
        <img
          className={styles.Animation}
          style={{
            marginTop: animMargin,
          }}
          src={animation}
          alt=""
        />
        <p
          className={styles.Text}
          style={{
            marginTop: textMargin,
          }}
        >
          {text}
        </p>
      </Fragment>
    );
  }
}

SlideBase.propTypes = {
  animation: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  scrollProgress: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};
