import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Logo } from '../../common/Logo/Logo';
import { Navigation } from './Navigation/Navigation';
import { ROUTES } from '../../../consts/routes';

import styles from './styles.scss';

export class Header extends Component {
  state ={
    openedMenu: false,
  }

  componentWillReceiveProps(nextProps) {
    const { currentPath } = this.props;
    const { currentPath: nextCurrentPath } = nextProps;

    if (currentPath !== nextCurrentPath) {
      this.handleBackClick();
    }
  }

  handleBackClick = () => this.setState({ openedMenu: false });

  handleToggleClick = () => {
    this.setState(({ openedMenu }) => ({
      openedMenu: !openedMenu,
    }));
  }

  render() {
    const { routes, currentPath } = this.props;
    const { openedMenu } = this.state;

    return (
      <div className={styles.Header}>
        <Link to={ROUTES.HOW_IT_WORKS}>
          <Logo className={styles.Logo} />
        </Link>
        <Navigation
          className={styles.Navigation}
          currentPath={currentPath}
          isOpen={openedMenu}
          onBack={this.handleBackClick}
          onToggle={this.handleToggleClick}
          routes={routes}
        />
      </div>
    );
  }
}

Header.propTypes = {
  currentPath: PropTypes.string.isRequired,
  routes: PropTypes.array.isRequired, // eslint-disable-line
};
