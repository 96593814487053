import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PulseLoader as Spinner } from 'react-spinners';

import styles from './styles.scss';

const noop = () => {};

export const RoundedButton = ({
  className,
  label,
  isDisabled,
  isLoading,
  onClick,
}) => {
  const onClickHandler = !isDisabled ? onClick : noop;

  return (
    <div
      className={classnames(
        styles.RectButton,
        {
          [styles.RectButton__disabled]: isDisabled,
          [styles.RectButton__loading]: isLoading,
        },
        className,
      )}
      onClick={onClickHandler}
      onKeyPress={onClickHandler}
      role="button"
      tabIndex={0}
    >
      {isLoading
        ? ''
        : label
      }
      {isLoading && (
        <Spinner color="#1b227d" size={12} />
      )}
    </div>
  );
};

RoundedButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

RoundedButton.defaultProps = {
  className: '',
  isDisabled: false,
  isLoading: false,
};
