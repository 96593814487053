import React from 'react';

import anim from '../../../../assets/slide_fifth.gif';

import { SlideBase } from '../SlideBase/SlideBase';

export const SlideFifth = props => (
  <SlideBase
    {...props}
    animation={anim}
    text="...a zaokrąglona reszta trafia na cele charytatywne."
  />
);
