import React from 'react';

import anim from '../../../../assets/slide_eight.gif';

import { SlideBase } from '../SlideBase/SlideBase';

export const SlideEight = props => (
  <SlideBase
    {...props}
    animation={anim}
    text="Wkrótce dołączą do nas sklepy w całym kraju."
  />
);
