import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import axios from 'axios';

import { Input } from '../Input/Input';
import { TextArea } from '../TextArea/TextArea';
import { RoundedButton } from '../RoundedButton/RoundedButton';
import { isEmail } from '../../../utils/validators';

import styles from './styles.scss';

export class QuestionForm extends Component {
  getInitialState = () => ({
    email: '',
    isLoading: false,
    message: '',
    name: '',
  });

  state = this.getInitialState();

  isValid = () => {
    const {
      email,
      message,
      name,
    } = this.state;

    if (email === ''
      || !isEmail(email)
      || message === ''
      || name === ''
    ) {
      return false;
    }

    return true;
  }

  handleChangeInput = type => ({ target: { value } }) => this.setState({ [type]: value });

  handleSendButton = () => {
    const {
      email,
      message,
      name,
    } = this.state;

    this.setState({ isLoading: true });

    const params = new URLSearchParams();
    params.append('name', name);
    params.append('email', email);
    params.append('message', message);

    axios.post('/message.php', params)
      .then(() => {
        this.setState(this.getInitialState());
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const {
      className,
      title,
      subtitle,
    } = this.props;
    const {
      email,
      isLoading,
      message,
      name,
    } = this.state;
    const isDisabled = !this.isValid();

    return (
      <div
        className={classnames(
          styles.QuestionForm,
          className,
        )}
      >
        <h4 className={styles.Title}>
          {title || 'Masz pytanie? Napisz do nas'}
        </h4>
        <h5 className={styles.Subtitle}>
          {subtitle || 'odezwiemy się w ciągu 24h'}
        </h5>
        <Input
          onChange={this.handleChangeInput('name')}
          placeholder="imię i nazwisko"
          value={name}
        />
        <Input
          onChange={this.handleChangeInput('email')}
          placeholder="email"
          type="email"
          value={email}
        />
        <TextArea
          onChange={this.handleChangeInput('message')}
          placeholder="wiadomość"
          value={message}
        />
        <RoundedButton
          className={styles.SendButton}
          label="Wyślij"
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={this.handleSendButton}
        />
      </div>
    );
  }
}

QuestionForm.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

QuestionForm.defaultProps = {
  className: '',
  title: null,
  subtitle: null,
};
