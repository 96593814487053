import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { ROUTES } from '../../../../consts/routes';
import { RectButton } from '../../../common/RectButton/RectButton';

import styles from './styles.scss';

class SlideTenthView extends Component {
  handleBeBeneficiary = () => {
    const { history } = this.props;

    history.push(ROUTES.BECOME_A_BENEFICIARY);
  }

  handleBePartner = () => {
    const { history } = this.props;

    history.push(ROUTES.BECOME_A_PARTNER);
  }

  render() {
    const { isMobile, scrollProgress } = this.props; // eslint-disable-line

    return (
      <Fragment>
        <RectButton
          className={styles.Button}
          label="Jak zostać beneficjentem?"
          onClick={this.handleBeBeneficiary}
        />
        <RectButton
          className={styles.Button}
          label="Jak zostać partnerem biznesowym?"
          onClick={this.handleBePartner}
        />
      </Fragment>
    );
  }
}

SlideTenthView.propTypes = {
  history: PropTypes.object.isRequired, // eslint-disable-line
  isMobile: PropTypes.bool.isRequired,
  scrollProgress: PropTypes.number.isRequired,
};

export const SlideTenth = withRouter(SlideTenthView);
