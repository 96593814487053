import React from 'react';

import anim from '../../../../assets/slide_seventh.gif';

import { SlideBase } from '../SlideBase/SlideBase';

export const SlideSeventh = props => (
  <SlideBase
    {...props}
    animation={anim}
    text="I jak ogromną kwotę można zebrać dzięki naszej akcji."
  />
);
