import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

export const Input = ({
  name,
  onChange,
  placeholder,
  type,
  value,
}) => (
  <div className={styles.Input}>
    <input
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      type="type"
      value={value}
    />
  </div>
);

Input.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

Input.defaultProps = {
  name: '',
  onChange() {},
  placeholder: '',
  type: 'text',
  value: '',
};
