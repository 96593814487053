import React from 'react';

import anim from '../../../../assets/slide_ninth.gif';

import { SlideBase } from '../SlideBase/SlideBase';

export const SlideNinth = props => (
  <SlideBase
    {...props}
    animation={anim}
    text={'Nasze "drobne" gesty mogą zmieniać Świat!'}
  />
);
