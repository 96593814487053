import React from 'react';

import Preloader from '../../assets/spinner.gif';

import styles from './styles.scss';

export const Spinner = () => (
  <div className={styles.Spinner}>
    <img
      alt=""
      src={Preloader}
    />
    <span>
      trwa zaokrąglanie...
    </span>
  </div>
);
