import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import { MainLayout } from '../MainLayout/MainLayout';

export const Routing = ({ routes }) => (
  <MainLayout
    routes={routes}
  >
    <Switch>
      {
        routes
          .map(route => (
            <Route
              key={route.path}
              path={route.path}
              exact={!!route.exact}
              strict={!!route.strict}
              component={route.component}
            />
          ))
      }
    </Switch>
  </MainLayout>
);

Routing.propTypes = {
  routes: PropTypes.array.isRequired, // eslint-disable-line
};
