import React from 'react';
import ReactModal from 'react-modal';
import Youtube from 'react-youtube';
import ScrollLock from 'react-scrolllock';
import PropTypes from 'prop-types';

import iconClose from '../../../assets/icon_close.svg';
import { VIDEO_ID } from '../../../consts/youtube';
import styles from './styles.scss';

const opts = {
  playerVars: {
    autoplay: 1,
    loop: 1,
    playlist: VIDEO_ID,
    rel: 0,
  },
};

export const MovieModal = ({ isOpen, onClose }) => (
  <ReactModal
    isOpen={isOpen}
    className={styles.Modal}
    onRequestClose={onClose}
    overlayClassName={styles.Overlay}
  >
    <div
      className={styles.TopBar}
      role="button"
      onClick={onClose}
      onKeyDown={onClose}
      tabIndex={0}
    >
      <img alt="" className={styles.IconClose} src={iconClose} />
    </div>
    <Youtube
      className={styles.Video}
      opts={opts}
      videoId={VIDEO_ID}
    />
    <ScrollLock />
  </ReactModal>
);

MovieModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

MovieModal.defaultProps = {
  isOpen: false,
  onClose() {},
};
