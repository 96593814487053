import React from 'react';

import animation from '../../assets/become_a_partner.gif';
import { PartnerForm } from '../common/PartnerForm/PartnerForm';
import { Logo } from '../common/Logo/Logo';
import { Footer } from '../common/Footer/Footer';

import styles from './styles.scss';

// eslint-disable-next-line
const text = 'Jesteś właścicielem sklepu, kwiaciarni, stacji benzynowej? Chcesz wziąć udział w projekcie ZAOKRĄGLAMY? Skontaktuj się z nami.';
// eslint-disable-next-line
const text2 = 'Techniczna i operacyjna implementacja systemu umożliwiającego zaokrąglanie jest finansowana przez firmy uczestniczące w projekcie.';
// eslint-disable-next-line
const text3 = 'Dzięki prawnikom z Kancelarii DZP powiemy Wam jak nasze przedsięwzięcie mieści się w ramach obowiązującego prawa.';

export const BecomePartner = () => (
  <div className={styles.BecomeParner}>
    <div className={styles.Columns}>
      <div className={styles.Column}>
        <div className={styles.Content}>
          <div className={styles.Top}>
            <img
              className={styles.Animation}
              src={animation}
              alt=""
            />
            <h2 className={styles.Title}>
              Zostań partnerem
            </h2>
          </div>
          <div className={styles.Text}>
            {text}
            <br />
            {text2}
            <br />
            {text3}
          </div>
        </div>
      </div>
      <div className={styles.Column}>
        <PartnerForm className={styles.Form} />
        <Logo className={styles.Logo} />
      </div>
    </div>
    <Footer />
  </div>
);
