import React from 'react';

import { QuestionForm } from '../common/QuestionForm/QuestionForm';
import { Logo } from '../common/Logo/Logo';
import { Footer } from '../common/Footer/Footer';

import styles from './styles.scss';

export const Contact = () => (
  <div className={styles.Contact}>
    <QuestionForm
      className={styles.Form}
      title="MASZ PYTANIE?"
      subtitle="Napisz do nas, odezwiemy się w ciągu 24h"
    />
    <div className={styles.Content}>
      <div className={styles.Row}>
        <h3>
          Projekt ZAOKRĄGLAMY
        </h3>
        <span className={styles.Text}>
          Projekt ZAOKRĄGLAMY realizowany jest przez&nbsp;
          <a href="http://dokladamsie.org" target="_blank" rel="noopener noreferrer">
            Fundację Dokładam Się
          </a>
          &nbsp;i jej partnerów,
          m. in.: Polski Czerwony Krzyż, Polska Akcja Humanitarna, Caritasy diecezjalne,
          Federacja Polskich Banków Żywności, Fundacja Dzieło Nowego Tysiąclecia.
          Pierwsze zbiórki ogólnopolskie mają się odbyć na wskazane przez nie cele.
          Następne zbiórki - regionalne - odbywać się będą na cele wskazane wspólnie
          przez lokalne organizacje i sklepy partnerskie projektu.
          Partnerem Fundacji Dokładam Się jest Kancelaria Domański Zakrzewski Palinka (DZP)
          – największa polska kancelaria prawna, która zapewnia bezpieczeństwo prawno-formalne
          projektów realizowanych przez Fundację.
        </span>
        <span>
          Kontakt&nbsp;
          <a href="mailto:fundacja@dokaladamsie.org">
            fundacja@dokaladamsie.org
          </a>
          &nbsp;Tomasz Kiersnowski tel.&nbsp;
          <a href="tel:+48607301539">
            607 301 539
          </a>
          .
        </span>
        <div className={styles.Separator} />
        <span className={styles.RODO}>
          Administratorem Twoich danych osobowych jest Fundacja Dokładam się z siedzibą w Warszawie (00-227),
          ul. Freta 20/24a („Fundacja”), kontakt: tel. 22 839 27 27, e-mail: fundacja@dokladamsie.org.
          Fundacja przetwarza Twoje dane osobowe w celu nawiązania i realizacji kontaktu – art. 6 ust. 1 lit.
          f RODO. Odbiorcami Twoich danych mogą być: dostawcy usług IT oraz wolontariusze Fundacji. Dane osobowe
          będą przetwarzane do momentu nawiązania i realizacji kontaktu, a po jego zakończeniu usunięte.
          Masz prawo do żądania dostępu do swoich danych osobowych, ich sprostowania, usunięcia lub ograniczenia
          przetwarzania, a także prawo do przenoszenia danych.
          Przysługuje Tobie prawo do zgłoszenia sprzeciwu wobec przetwarzania danych opartego
          na art. 6 ust. 1 lit. f RODO. Masz prawo do wniesienia skargi do organu nadzorczego
          – Prezesa Urzędy Ochrony Danych Osobowych. Podanie danych osobowych jest dobrowolne,
          a niepodanie danych uniemożliwi nawiązanie i realizację kontaktu z Fundacją.
        </span>
        <Logo className={styles.Logo} />
      </div>
    </div>
    <div className={styles.FooterWrapper}>
      <Footer />
    </div>
  </div>
);
