import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import LogoImg from '../../../assets/logo.svg';

import styles from './styles.scss';

export const Logo = ({ className }) => (
  <div
    className={classnames(
      styles.LogoWrapper,
      className,
    )}
  >
    <img
      alt=""
      className={styles.Logo}
      src={LogoImg}
    />
  </div>
);

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: '',
};
