import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { Header } from '../Header/Header';

export const MainLayoutView = ({
  children,
  location: {
    pathname,
  },
  routes,
}) => {
  const navLinks = routes
    .filter(({ path }) => !path.includes('*'));

  return (
    <Fragment>
      <Header
        currentPath={pathname}
        routes={navLinks}
      />
      <div>
        {children}
      </div>
    </Fragment>
  );
};

MainLayoutView.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired, // eslint-disable-line
  routes: PropTypes.array.isRequired, // eslint-disable-line
};

export const MainLayout = withRouter(MainLayoutView);
