import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.scss';

export const RectButton = ({
  className,
  label,
  onClick,
}) => (
  <div
    className={classnames(
      styles.RectButton,
      className,
    )}
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex={0}
  >
    {label}
  </div>
);

RectButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

RectButton.defaultProps = {
  className: '',
};
